/**
 * DataLayer - buttonClick event
 * @description по нажатию на любую кнопку срабатывает событие "buttonClick"
 * @description определенная кнопка может находится только в определенном блоке в ином случае ТС даст ошибку, по необходимости добавить нужным кнопкам placement
 *
 * @example DLClickEvent({ name: 'sticky', placement: 'header', eventLabel?: globalThis?.location?.href})
 *
 * @default event: "buttonClick"
 * @param name: Название кнопки в dataLayer
 * @param placement: Расположение кнопки в секции\блоке
 * @param eventLabel: Страница на которой произошло событие
 */

import { pushToDL } from "../utils/helper"
import type { TDLPageURL } from "./type"

export type TDLButtonsVariant =
  | "sticky" // Стики кнопка
  | "click_cta" // CTA кнопка (оформить, получить и пр.)
  | "calc_sum_credit" // Калькулятор. Ползунок суммы
  | "loan_term_selection" // Калькулятор. Срок кредита
  | "monthly_payment" // Калькулятор. Ежемесячный платеж
  | "click_switcher_content" // Полезная информация, клик по табам
  | "click_qa" // Полезная информация, раскрытие блоков
  | "open_faq" // Вопросы и ответы
  | "other_offers" // Другие предложение по кредитам
  | "enter_esia" // Гос.услуги - войти
  | "more_details_esia" // Гос.услуги - подробнее
  | "phone_number" // Клик по номеру горячей линии
  | "view_products" // Экран дубля. Клик “Посмотреть товары
  | "go_back_to_the_main" // Экран отказы. Клик “Вернуться на главную
  | "refresh_the_page" // Экран ошибки. Клик “Обновить страницу
  | "logo" // Клик по логотипу
  | "timer" // Клик по таймеру
  | "tooltip" // Клик по тултипу
  | "Халва.Десятка в подарок" // Клик по всему блоку TopCta

export type TDLPlacementBlocks =
  | "header"
  | "burger_menu"
  | "footer"
  | "banner"
  | "calculator"
  | "benefits"
  | "switcher_content"
  | "faq"
  | "esia"
  | "error_screen"
  | "banner-refin"
  | "top_button_halva"

const buttonsPosition = {
  sticky: ["header", "banner"],

  click_cta: [
    "header",
    "footer",
    "banner",
    "calculator",
    "benefits",
    "switcher_content",
    "burger_menu",
    "banner-refin",
  ],

  calc_sum_credit: ["calculator"],
  loan_term_selection: ["calculator"],
  monthly_payment: ["calculator"],

  click_switcher_content: ["switcher_content"],
  click_qa: ["switcher_content"],

  open_faq: ["faq"],

  other_offers: ["footer"],

  enter_esia: ["esia"],
  more_details_esia: ["esia"],

  phone_number: ["footer", "header"],

  view_products: ["error_screen"],
  go_back_to_the_main: ["error_screen"],
  refresh_the_page: ["error_screen"],

  logo: ["header"],
  timer: ["header"],
  tooltip: ["calculator"],
  "Халва.Десятка в подарок": ["top_button_halva"],
} satisfies Record<TDLButtonsVariant, TDLPlacementBlocks[]>

export const DLClickEvent = <
  TBtnName extends TDLButtonsVariant,
  TPlacement extends (typeof buttonsPosition)[TBtnName] extends Array<infer T> ? T : never
>({
  name,
  placement,
  eventLabel = globalThis?.location?.href,
}: {
  name: TBtnName
  placement: TPlacement
  eventLabel?: TDLPageURL
}) => {
  pushToDL({ event: "buttonClick", name, placement, eventLabel })
}
